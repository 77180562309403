<script>
import Slider from "@vueform/slider";

export default {
  data() {
    return {
      value: 0,
      speed: 1,
      cssPlayButtonClass: 'ri-play-line',
      cssMuteToggle: 'ri-volume-up-line',
      playBeforeMove: false,
    };
  },
  props: {
    data: {
      type: Object,
      default: function () {
        return {
          id: null,
          title: '',
          memo: '',
          urlAvatar: '',
          urlCover: '',
        }
      }
    },
    podcast: {
      type: Object,
      default: function () {
        return {
          id: null,
          title: '',
          memo: '',
          urlAvatar: '',
          urlCover: ''
        }
      }
    }
  },
  components: {
    Slider,
  },
  mounted() {
    if(this.data.stat.isFavourite) {
      this.favClass = 'ri-bookmark-fill text-warning';
    }
    else {
      this.favClass = 'ri-bookmark-line';
    }
    this.favCount = this.data.stat.favourites;

    if(this.data.urlAudioFile) {
      var vm = this;
      this.$refs.player.onplay = function() {
        vm.cssPlayButtonClass = 'ri-pause-line';
      };
      this.$refs.player.onpause = function() {
        vm.cssPlayButtonClass = 'ri-play-line';
      };
      this.$refs.player.addEventListener("timeupdate", this.changedTime, true);
    }
  },
  methods: {
    playRecord: function() {
      if(this.cssPlayButtonClass === 'ri-pause-line') {
        this.$refs.player.pause();
      } else {
        this.$refs.player.play();
      }
    },
    changedTime: function() {
      if(this.$refs.player) {
        this.value = this.$refs.player.currentTime;
      }
      event.preventDefault();
    },
    endOfRecord: function() {
      this.cssPlayButtonClass = 'ri-play-line';
      this.value = 0;
    },
    setSpeedRate(e) {
      this.speed = e;
      this.$refs.player.playbackRate = e;
    },
    toggleMute() {
      if(this.$refs.player.muted) {
        this.$refs.player.muted = false;
        this.cssMuteToggle = 'ri-volume-up-line';
      }
      else {
        this.$refs.player.muted = true;
        this.cssMuteToggle = 'ri-volume-mute-line';
      }
    },
    moveToLeft(e) {
      var value = this.$refs.player.currentTime - e;
      if(value < 0)
        this.$refs.player.currentTime = 0;
      else
        this.$refs.player.currentTime = value;
    },
    moveToRight(e) {
      var value = this.$refs.player.currentTime + e;
      if(value > this.$refs.player.duration)
        this.$refs.player.currentTime = 0;
      else
        this.$refs.player.currentTime = value;
    },
    timeLineMove(e) {
      this.$refs.player.currentTime = e;
      this.value = e;
      event.preventDefault();
    },
    formatTooltip(v) {
      var date = new Date(null);
      date.setSeconds(v);
      return date.toISOString().substring(11, 19);
    },
    beforeMove() {
      this.playBeforeMove = this.$refs.player.paused;
      if(!this.playBeforeMove)
        this.$refs.player.pause();
    },
    afterMove() {
      if(!this.playBeforeMove)
        this.$refs.player.play();
    },
  },
  computed: {
    getSpeedText: function() {
      return this.speed + "x";
    },
  }
};
</script>

<template>

  <div class="card team-box ribbon-box ribbon-fill">
    <div class="team-cover" v-if="data.urlCover">
      <img v-bind:src="data.urlCover" alt="" class="img-fluid" />
    </div>
    <div class="card-body p-4">
      <div class="ribbon ribbon-danger" v-if="data.flags.isNew"><small>New</small></div>
      <div class="row align-items-center team-row">
        <div class="col team-settings">
          <div class="row">
            <div class="col"></div>
            <div class="col text-end dropdown">
              <a href="javascript:void(0);" data-bs-toggle="dropdown" aria-expanded="false">
                <i class="ri-more-fill fs-17"></i>
              </a>
              <ul class="dropdown-menu dropdown-menu-end">
                <li>
                  <router-link :to="{ name: 'episode', params: { id: data.id } }" class="dropdown-item">
                    <i class="ri-eye-line me-2 align-middle"></i>View
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col">

          <div class="team-profile-img">
            <div class="avatar-xl img-thumbnail rounded-circle flex-shrink-0">
              <router-link :to="{ name: 'episode', params: { id: data.id } }">
                <div class="avatar-title rounded-circle text-center" v-if="!data.urlAvatar">{{ data.title }}</div>
                <img v-bind:src="data.urlAvatar" alt="" class="img-fluid d-block rounded-circle" v-if="data.urlAvatar" />
              </router-link>
            </div>
            <div class="team-content">
              <router-link :to="{ name: 'episode', params: { id: data.id } }">
                <h5 class="fs-16 mb-1">{{ data.title }}</h5>
              </router-link>
              <p class="text-muted mb-1">{{ data.short }}</p>
              <router-link :to="{ name: 'podcast', params: { name: podcast.link }}" class="text-primary">{{ podcast.title }}</router-link>
            </div>
          </div>

          <div class="text-center pt-4 pb-4" v-if="data.urlAudioFile">
            
            <audio id="episodePlayer" ref="player" :src="data.urlAudioFile" preload="none"></audio>
            <Slider v-model="value" v-bind:tooltips="true" @change="timeLineMove" @start="beforeMove" @end="afterMove" :format="formatTooltip" class="primary-slider mb-3" :max="data.duration" show-tooltip="focus" />

            <b-button variant="primary" pill size="md me-2" @click="moveToLeft(10)">
              <i class="ri-arrow-go-back-line"></i>
            </b-button>
            <b-button variant="light" pill size="lg me-2" @click="playRecord">
              <i class="fs-1" :class="cssPlayButtonClass"></i>
            </b-button>
            <b-button variant="primary" pill size="md" @click="moveToRight(10)">
              <i class="ri-arrow-go-forward-line"></i>
            </b-button>

            <br/>

            <b-button-group class="mt-4">
              <b-dropdown size="sm" variant="primary" :text="getSpeedText" >
                <b-dropdown-item @click="setSpeedRate(0.5)">0.5</b-dropdown-item>
                <b-dropdown-item @click="setSpeedRate(0.75)">0.75</b-dropdown-item>
                <b-dropdown-item @click="setSpeedRate(1)">1</b-dropdown-item>
                <b-dropdown-item @click="setSpeedRate(1.25)">1.25</b-dropdown-item>
                <b-dropdown-item @click="setSpeedRate(1.5)">1.5</b-dropdown-item>
                <b-dropdown-item @click="setSpeedRate(2)">2</b-dropdown-item>
                <b-dropdown-item @click="setSpeedRate(2.25)">2.25</b-dropdown-item>
                <b-dropdown-item @click="setSpeedRate(2.5)">2.5</b-dropdown-item>
              </b-dropdown>
              <b-button variant="primary" size="sm" @click="toggleMute">
                <i :class="cssMuteToggle"></i>
              </b-button>
            </b-button-group>
            
          </div>

        </div>

      </div>
    </div>
  </div>

  <div class="card" v-if="data.urlPoster">
    <img class="card-img-top img-fluid" v-bind:src="data.urlPoster" alt="">
  </div>

</template>