<script>
export default {
  data() {
    return {
    }
  },
  props: {
    data: {
      type: Object,
      default: function () {
        return {
        }
      }
    },
    category: {
      type: Object,
      default: function () {
        return {
        }
      }
    }
  },
  components: {
  },
  computed: {
    rssFeed: function() {
      return process.env.VUE_APP_URL + 'tools/rss/podcast/' + this.data.link;
    }
  }
};
</script>

<template>
  <div class="card mb-3">
    <div class="card-body">
      <h6 class="text-muted text-uppercase fw-semibold mb-3"> Podcast details </h6>
      <p class="text-muted mb-4" v-if="false"></p>
      <div class="table-responsive table-card">
        <table class="table table-borderless mb-0 sidebar-rows">
          <tbody>
            <tr>
              <td class="fw-medium" scope="row">Created</td>
              <td>{{ new Date(data.createdAt).toLocaleString() }}</td>
            </tr>
            <tr v-if="this.category && this.category.id">
              <td class="fw-medium" scope="row">Category</td>
              <td>
                <router-link :to="{ name: 'podcasts-categories', params: { 'id': this.category.id }}">{{ this.category.title }}</router-link>
              </td>
            </tr>
            <tr v-if="data.share === 2">
              <td class="fw-medium" scope="row">RSS</td>
              <td><a :href="rssFeed" target="_blank"><i class="ri-rss-line"></i> Podcast feed</a></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
